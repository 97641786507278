import axios from "axios";
import Airtable from 'airtable';
import { ICategory, IJob } from "./interfaces";
import { config } from './config/index';

const AxiosConfig = axios.create({
    baseURL: "https://django-job.herokuapp.com/api/"
});

export default AxiosConfig;


export class Api {
    constructor() {
        this.base = new Airtable({ apiKey: config.airtable.token }).base('appDtKsoi5Gnouyaf');
    }
    base: Airtable.Base;

    async findJobs() {
        let jobs: Array<IJob> = [];
        await this.base('Jobs').select({
            sort: [
                { field: 'created_at', direction: 'asc' }
            ]
        }).eachPage(function page(records, fetchNextPage) {
            records.forEach(function (record) {
                const job:IJob = {
                    id: Number(record.get('id')),
                    category: record.get('category') as string,
                    created_at: new Date(record.get('created_at') as string),
                    description: record.get('description') as string,
                    location: record.get('location') as string,
                    title: record.get('title') as string,
                    tags: record.get('tags') as Array<string>,
                    type: record.get('type') as string,
                    company_name: record.get('company_name') as string

                };
                jobs.push(job);
            });
            fetchNextPage();
        });
        return jobs;
    }

    async findCategories() {
        let categories: Array<ICategory> = [];
        await this.base('Categories').select({
            sort: [
                { field: 'total_jobs', direction: 'desc' }
            ]
        }).eachPage(function page(records, fetchNextPage) {
            records.forEach(function (record) {
                const job:ICategory = {
                    id: Number(record.get('id')),
                    icon: record.get('icon') as string,
                    name: record.get('title') as string,
                    slug: record.get('slug') as string,
                    total_jobs: record.get('total_jobs') as number,
                };
                categories.push(job);
            });
            fetchNextPage();
        });
        return categories;
    }
}