import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import common_bn from "./translations/bn/common.json";
import common_en from "./translations/en/common.json";
import common_pt_br from "./translations/pt-br/common.json";

import employee_bn from "./translations/bn/employee.json";
import employee_en from "./translations/en/employee.json";

import jobs_en from "./translations/pt-br/jobs.json";
import jobs_bn from "./translations/pt-br/jobs.json";
import jobs_pt_br from "./translations/pt-br/jobs.json";

const Languages = ["en", "bn", 'pt'];
const resources = {
    en: {
        translations: {
            common: common_en,
            employee: employee_en,
            jobs: jobs_en,
        },
    },
    bn: {
        translations: {
            common: common_bn,
            employee: employee_bn,
            jobs: jobs_bn,
        }
    },
    'pt': {
        translations: {
            common: common_pt_br,
            employee: employee_bn,
            jobs: jobs_pt_br
        }
    },
}

export default i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "pt",
        debug: true,
        whitelist: Languages,
        interpolation: {
            escapeValue: false,
        },
        ns: ['common', 'employee', 'jobs'],
        // Set default namespace
        defaultNS: "translations",
        resources,
    });